import React from "react"
import CodeBlock, { setDefaultPrompt } from "../../components/codeblock"
import RendererBlock from '../../components/rendererblock'

import style from "./test.module.css"

require('prismjs/components/prism-bash');

export default () => {

    setDefaultPrompt('ben', 'xps');

    return (
        <div>
            <div style={{height: "50vh", position: 'relative'}} className={style.outer}>
                <p className={style.bottom}>Hello</p>
            </div>
            <div style={{height: "0vh"}} />
            <CodeBlock language="javascript"  filepath="project/src/hello.js" code={
`
const webpack = require('webpack');
const Hwp = require('html-webpack-plugin');

// this is a comment!

const myDefault = 3000;
const untrue = false;
const reg = /^thisisa.regex$/
let x = 'mystring';
`}/>

            <div style={{height: "100vh"}} />

        </div>
        
    )
}